import React from 'react'
import Layout from '../../components/Layout'

export default () => (
    <Layout >
        <section className="section">
            <div className="container">
                <div className="content">
                    <h1>Thanks for your interest</h1>
                    <p>We shall contact you soon.</p>
                </div>
            </div>
        </section>
    </Layout>
)